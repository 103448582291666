import React, { useState } from "react";
import styled from "styled-components";

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #282c34;
`;

const Header = styled.h1`
  color: #fff;
  text-align: center;
  padding: 20px 0;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  max-width: 500px;
  background-color: #fff;
`;

const Label = styled.label`
  margin: 10px 0;
  color: #333;
`;

const Input = styled.input`
  padding: 10px;
  margin: 0 0 10px 0;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const Button = styled.button`
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: #0069d9;
  }

  &:active {
    background-color: #005cbf;
  }
`;

function FormComponent() {
  const initialFormData = {
    date: "",
    system: "",
    equipment: "",
    observation: "",
    severity: "",
    solution: "",
    comments: "",
    issuer: ""
  };
  const [formData, setFormData] = useState(initialFormData);
  
  const handleSubmit = (event) => {
    event.preventDefault();
    const jsonData = JSON.stringify(formData);

    if (window.location.hash) {
      const queryHash = window.location.hash;
      var token_value = queryHash.replace(/^#id_token=|&.*$/gm, '');
    }

    fetch("https://7rt5jklvy6.execute-api.us-east-1.amazonaws.com/default/forms/users", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": token_value,
        "Access-Control-Allow-Origin": "*", // Required for CORS support to work
        "Access-Control-Allow-Credentials": true, // Required for cookies, authorization headers with HTTPS
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Methods': 'OPTIONS,POST,GET'
      },
      body: jsonData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setFormData(initialFormData); // Reset form data to initial values
        window.location.reload(); // Refresh the page
      })
      .catch((error) => console.error(error));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  return (
    <AppWrapper>
      <Header>Field Observations</Header>
      <button>V3</button>
      <a href="https://mgmt-dev.auth.us-east-1.amazoncognito.com/login?client_id=6ifqsiq3kud2pcjrv5csiga7oc&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https%3A%2F%2Fmgmt-dev.nodestack.cloud">
        <button>Go to LOGIN</button>
      </a>
      <Form onSubmit={handleSubmit}>
        <Label>
          Date:
          <Input type="datetime-local" name="date" value={formData.date} onChange={handleInputChange} />
        </Label>
        <Label>
          System:
          <Input type="text" name="system" value={formData.system} onChange={handleInputChange} />
        </Label>
        <Label>
          Equipment:
          <Input type="text" name="equipment" value={formData.equipment} onChange={handleInputChange} />
        </Label>
        <Label>
          Observation:
          <Input type="text" name="observation" value={formData.observation} onChange={handleInputChange} />
        </Label>
        <Label>
          Severity:
          <Input type="text" name="severity" value={formData.severity} onChange={handleInputChange} />
        </Label>
        <Label>
          Solution:
          <Input type="text" name="solution" value={formData.solution} onChange={handleInputChange} />
        </Label>
        <Label>
          Comments:
          <Input type="text" name="comments" value={formData.comments} onChange={handleInputChange} />
        </Label>
        <Button type="submit">Submit</Button>
      </Form>
    </AppWrapper>
  );
}

export default FormComponent;
